import { createStyles, Title, Group, rem, MultiSelect } from '@mantine/core';
import { IconBuildingHospital } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { AppApi } from '../services/api/app-get/Api';
import { Environment } from '../environment';
import { notifications } from '@mantine/notifications';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(120),
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
  },
  inner: {
    display: 'flex',
    width:'100%',
    marginTop:'5vh',
    justifyContent: 'center',
    flexDirection:'column',
  },
  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(60),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][3] : theme.colors.dark[4],
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(38),
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(32),
    },
  },

}));

export function FirstAccess() {
  const { classes } = useStyles();
  const { fetchUnit, unit, group } = useContext(DataContext);
  const [unidade, setUnidade] = useState([]);

    useEffect(()=>{
        if(unit){
            const selectData = unit.map(item => ({
                value: item.UNI_ID,
                label: item.UNI_PARA
                }));
            setUnidade(selectData)
        }else{
            fetchUnit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[unit])



    const handleChanceHosp = async (event) =>{
        const data = JSON.stringify({bsc: event})
        await AppApi(`/app/bsc/${Environment.APP_ID}`, 'POST', data).then(() =>
        notifications.show({
          title: 'Minha area',
          color:'green',
          message: 'Área(s) atualiza(s) com sucesso! :)',
        })
        
        ).catch((err)=>{console.log(err)})

    }

  return (
    <div className={classes.inner}>
        <Title className={classes.title}>Selecione suas Áreas</Title>
        <Group position="center">
            <MultiSelect
                sx={{width:'30vw', marginTop:'3vh'}}
                icon={<IconBuildingHospital/>}
                placeholder="Digite o nome para pesquisar..."
                searchable
                size='xl'
                defaultValue={`${group}`}
                nothingFound="Sem dados"
                data={unidade}
                clearable
                onChange={(e) => handleChanceHosp(e)}
            />
        </Group>
    </div>
  );
}