import { useState } from 'react';
import {
  Badge,
  Box,
  Card,
  Drawer,
  Grid,
  Group,
  SegmentedControl,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { useContext, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import { useDisclosure } from '@mantine/hooks';
import { JustifyBench } from './JustifyBench';

const Status = (data, month) => {
  const indicator = data; // Assumindo apenas um indicador
  const targetMonth = indicator.find((item) => item.MES === month);

  if (targetMonth && targetMonth.VALOR && targetMonth.META) {

    var valor = `${targetMonth.VALOR}`;
    var meta = (targetMonth.META ? `${targetMonth.META.META}` : '0');
    valor = valor.replace('%', '');
    meta = meta.replace('%', '');
    meta = meta.replace('.', '');
    valor = valor.replace('.', '');
    meta = meta.replace(',', '.');
    valor = valor.replace(',', '.');
    const sortedStatus = targetMonth.STATUS.sort((a, b) => b.STATUS.localeCompare(a.STATUS));

    // Obtém o primeiro valor após a ordenação
    const primeiroStatus = sortedStatus[0]?.STATUS || "N/A";

    if (Number(valor) <= Number(meta)) {
      return 'green';
    } else if (!targetMonth.STATUS && Number(valor) > Number(meta)) {
      return 'red';
    } else if (targetMonth.STATUS && primeiroStatus === 'Em andamento') {
      return 'yellow';
    } else if (targetMonth.STATUS && primeiroStatus === 'Concluído') {
      return 'blue';
    } else if (Number(valor) === '') {
      return 'dark';
    }
  } else {
    return 'dark';
  }
};

function DetailsDrawer({ opened, onClose, selectedMonth }) {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position='right'
      size={'60%'}
      overlayProps={{ opacity: 0.5, blur: 4 }}
      closeOnClickOutside={false}
    > 
      <JustifyBench data={selectedMonth}/>
    </Drawer>
  );
}

const Month = ({ data, indicator, area, ano, handleBadgeClick }) => {
  const months = [];
  
  for (let mes = 1; mes < 13; mes++) {
    const details = {indicator: indicator, area: area, ano: ano, mes: mes};
    months.push(
      <Badge
        color={Status(data, mes)}
        onClick={() => handleBadgeClick(details)} // Passa o valor de 'mes' para a função handleBadgeClick
        sx={{ margin: 1 }}
        size={6}
        radius="sm"
        key={mes}
      >
        {mes}
      </Badge>
    );
  }

  return <>{months}</>;
};

export const PainelBench = () => {
  const [opened, { toggle }] = useDisclosure(); // Use o estado 'opened' retornado pelo hook 'useDisclosure'
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [bscData, setBscData] = useState([])
  const { data } = useContext(DataContext);

  const handleBadgeClick = (data) => {
    setSelectedMonth(data);
    toggle(); // Abre o Drawer
  };

  useEffect(()=>{
    if (Array.isArray(data) && data !== '') { // Verificar se data é um array
      setBscData(data);
      console.log(JSON.stringify(data))
    }else{
      //fetchData();
    }
    
  }, [data]);

  const Ano = () =>{
      
    return(
    <SegmentedControl
    sx={{marginTop:40}}
    data={[
      { value: '2023', label: '2023', disabled: false},
      { value: '2022', label: '2022', disabled: true},
      { value: '2021', label: '2021', disabled: true},
    ]}
  />
  )}

  return (
    <Box>

      <Ano/>
    
    <SimpleGrid sx={{ marginTop: 60 }} cols={2} spacing="sm" verticalSpacing="sm">
      {bscData.map((value) => (
        <Card shadow="sm" padding="lg" radius="md" withBorder key={value.UNI_DE}>
          <Card.Section></Card.Section>

          <Group position="apart" mt="md" mb="xs">
            <Text weight={500}>{value.UNI_DE}</Text>
            <Badge color="pink" radius="sm" variant="light">
              {value.ANO}
            </Badge>
          </Group>

          {
            
            // eslint-disable-next-line array-callback-return
            value.INDICADORES.map((indi) => {
              if(indi.IND_TIPO === 5){
                return (
                  <Grid key={indi.IND_NOME}>
                  <Grid.Col xs={6}>
                    <Badge
                      color="dark"
                      variant="light"
                      radius="sm"
                    >
                      <Text sx={{ fontSize: 10 }}>{indi.IND_NOME}</Text>
                    </Badge>
                  </Grid.Col>
                  <Grid.Col xs={6}>
                    <Box sx={{ marginLeft: 28 }}>
                      <Month
                        data={indi.MESES}
                        indicator={indi.IND_NOME}
                        area={value.UNI_PARA}
                        ano={value.ANO}
                        handleBadgeClick={handleBadgeClick} // Passe a função handleBadgeClick para a função Month
                      />
                    </Box>
                  </Grid.Col>
                </Grid>
                )
              }
  
            })
  
          
          }
        </Card>
      ))}
        <DetailsDrawer opened={opened} onClose={toggle} selectedMonth={selectedMonth} />
    </SimpleGrid>
    </Box>
  );
};
