import { Navbar, Center, Tooltip, UnstyledButton, createStyles, Stack, rem, useMantineTheme, Avatar } from '@mantine/core';
import {
  IconClipboardData, IconArrowsExchange2,
  IconLogout,
} from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom/dist';
import { Environment } from '../environment';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const useStyles = createStyles((theme) => ({
  link: {
    width: rem(50),
    height: rem(50),
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  active: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

function NavbarLink({ icon: Icon, label, active, onClick }) {
  
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
        <Icon size="1.2rem" stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const mockdata = [
  { icon: IconClipboardData, label: 'Registro de Recoletas', link:'Home' },
  { icon: IconArrowsExchange2, label: 'Selecionar suas Áreas', link:'Areas'},
];

export function NavbarMinimal() {
  const { logout, user } = useContext(AuthContext)
  const location = useLocation();
  const currentPath = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const links = mockdata.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.link}
      active={link.link === currentPath}
      onClick={() => navigate(`${Environment.APP_FOLDER}/${link.link}`)}
    />
  ));

  return (
    <Navbar height={'100%'} width={{ base: 80 }} p="md">
      <Center>
        <img alt='Logo' style={{color:'red', width:50, height:50}} src={require(theme.colorScheme === 'dark' ? '../assets/images/sgdi_white.png' : '../assets/images/sgdi_red.png')}/>
      </Center>
      <Navbar.Section grow mt={50}>
        <Stack justify="center" spacing={0}>
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section>
        <Stack justify="center" justifyContent='center'>
          <Avatar ml={6} alt={user ? user.displayName.split(' ')[0] : null} src={`https://sgdi.fleury.com.br/api/uploads/profile/${user ? user.mailNickname : null}/profile.jpg`} />
          <NavbarLink icon={IconLogout} label="Logout" onClick={(e) => logout()} />
        </Stack>
      </Navbar.Section>
    </Navbar>
  );
}