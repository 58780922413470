import {
    createStyles,
    Title,
    rem,
    Text,
  } from '@mantine/core';
import { ThemeToggle } from '../utils/ThemeToggle';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Environment } from '../environment';
  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      width:'90%',
      justifyContent: 'space-between',
      alignItems:'center',
      paddingTop: `calc(${theme.spacing.xl} * 1)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      paddingLeft: `calc(${theme.spacing.xl} * 5)`,
    },
  
    content: {
      maxWidth: rem(480),
      marginRight: `calc(${theme.spacing.xl} * 3)`,
      color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: rem(24),
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: rem(28),
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: `${rem(4)} ${rem(12)}`,
    },
  }));
  
  export function Header() {
    const { classes } = useStyles();
    const { user } = useContext(AuthContext);
    return (
      <div>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Olá <span className={classes.highlight}>{(user ? user.displayName.split(' ')[0] : null)}</span>! <br /> 
              </Title>
              <Text>Você está em <b>{Environment.APP_NAME}</b></Text>
            </div>
            <ThemeToggle/>
          </div>
      </div>
    );
  }