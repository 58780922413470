export const Environment = {
    LIMITE_DE_LINHAS: 5,

    INPUT_DE_BUSCA: 'Pesquisar aqui',
    APP_NAME: 'Plano de Ação',
    APP_ID: 5,
    APP_FOLDER: 'app-bsc',
    LISTAGEM_VAZIA: 'Nenhum registro encontrado.',

    API_KEY:"566MR^g]Sac,:sXEn'Uq{VR@lBT2;d5J-mfA-<%$yCu6EEFSI@80L&Fc#-o=x$X",    
    //URL_BASE: 'https://sgdi.fleury.com.br/api/',
    URL_BASE: 'http://localhost:3000',
};