import React, { createContext, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

// Contexto de tema
export const ThemeContext = createContext();


// Componente de tema
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark');
    const toggleTheme = () => {
      setTheme(theme === 'light' ? 'dark' : 'light');
      
    };
    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <MantineProvider theme={{colorScheme: theme,  colors: {
          // override dark colors to change them for all components
          dark: [
            '#d5d7e0', // texto
            '#acaebf',
            '#8c8fa3',
            '#666980',
            '#4d4f66', // borda kpi
            '#25262B', // botao slide
            '#373A40', // fundo kpis
            '#2C2E33', // backgroud
            '#141517', // fundo botao slide
            '#01010a',
          ],
          bodyBackground: '#000',
        }, primaryColor:'red'}} 
          withGlobalStyles withNormalizeCSS>
            <Notifications />
              {children}
          </MantineProvider>
      </ThemeContext.Provider>
    );
  };