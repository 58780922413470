import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Group, Avatar, Text, Box, Badge, Textarea, SimpleGrid, Select } from '@mantine/core';
import { AppApi } from '../services/api/app-get/Api';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { DataContext } from '../context/DataContext';
import { FormatDate } from '../utils/FormatDate';

export function CollapsibleTable({ valuesData }) {
  const [expanded, setExpanded] = useState([]);
  const [data, setData] = useState([]);
  const { fetchData } = useContext(DataContext)

  const handleCollapseToggle = (index) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((item) => item !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };

  useEffect(() => {
    handlePlans()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePlans = async () => {
    //const indi  = (valuesData.indicator == 'CUSTO TECNICO / RECEITA' ? 'RECEITA' : valuesData.indicator );
    const ajustData = { area: valuesData.area, indi: valuesData.indicator, ano: valuesData.ano, mes: valuesData.mes }
    const plans = await AppApi(`/bsc/plan/`, 'POST', JSON.stringify(ajustData));
    setData(plans);

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode processar os dados do formulário, enviar para uma API, etc.
    const valuePlan = {
      ID: e.target.ID.value,
      ANO: valuesData.ano,
      MES: valuesData.mes,
      SETOR: valuesData.area,
      INDICADOR: valuesData.indicator,
      RISCO: e.target.RISCO.value,
      CAUSA_RAIZ: e.target.CAUSA_RAIZ.value,
      MEDIDA_DETECCAO: e.target.MEDIDA_DETECCAO.value,
      CORRECAO_IMEDIATA: e.target.CORRECAO_IMEDIATA.value,
      CORRECAO_RAIZ: e.target.CORRECAO_RAIZ.value,
      PRAZO: e.target.PRAZO.value,
      STATUS: e.target.STATUS.value,
      EFICAZ: e.target.EFICAZ.value,
    }
    const plano = JSON.stringify(valuePlan)
    AppApi(`/bsc/plano/update/`, 'POST', plano).then(() => {
      notifications.show({
        title: 'Sucesso!',
        color: 'green',
        message: 'Plano atualizado com sucesso!',
      })
      fetchData()
    }).catch(() => {
      notifications.show({
        title: 'Erro!',
        color: 'red',
        message: 'Ocorreu algum problema, tente novamente!',
      })
    })

  };

  return (
    <Box>
      <Text sx={{ fontSize: 32, marginTop: 80 }}>Plano de Ação</Text>
      <Table sx={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Modo de falha</th>
            <th>Responsável pelo plano</th>
            <th>Prazo</th>
            <th>Status</th>
            <th>RPN</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{item.MODO_FALHA}</td>
                <td>
                  <Group spacing="sm">
                    <Avatar size={40} src={`https://sgdi.fleury.com.br/api/uploads/profile/${item.RESP_LOGIN.toLowerCase()}/profile.jpg`} radius={40} />
                    <div>
                      <Text fz="sm" fw={500}>
                        {item.RESP_NOME}
                      </Text>
                      <Text fz="xs" c="dimmed">
                        {item.RESP_EMAIL}
                      </Text>
                    </div>
                  </Group>
                </td>
                <td>{FormatDate(item.PRAZO)}</td>
                <td>
                  <Badge size="md" radius="sm">{item.STATUS}</Badge>
                </td>
                <td>
                  <Badge size="md" radius="sm">{item.RPN}</Badge>
                </td>
              </tr>
              {expanded.includes(index) && (
                <tr>
                  <td colSpan={5} >
                    <form onSubmit={handleSubmit}>

                      <SimpleGrid sx={{ marginTop: 10 }} cols={3} spacing="xl" verticalSpacing="xl">

                        <Textarea
                          required
                          label="Risco"
                          defaultValue={item.RISCO}
                          name='RISCO'
                          minRows={3}
                        />

                        <Textarea
                          required
                          label="Causa raiz"
                          defaultValue={item.CAUSA_RAIZ}
                          name='CAUSA_RAIZ'
                          minRows={3}
                        />

                        <Textarea
                          required
                          label="Medidas de detecção"
                          placeholder="Ex: Análise laboratorial revela quantidade insuficiente de material biológico para análise completa"
                          defaultValue={item.MEDIDA_DETECCAO}
                          name='MEDIDA_DETECCAO'
                          minRows={3}
                        />
                      </SimpleGrid>
                      <SimpleGrid sx={{ marginTop: 10 }} cols={2} spacing="xl" verticalSpacing="sm">

                        <Textarea
                          required
                          label="Ação para correção imediata"
                          placeholder="Ex: Melhorar as instruções de coleta, fornecer treinamento adicional para profissionais de saúde, implementar procedimentos de controle de qualidade para garantir coletas adequadas."
                          defaultValue={item.CORRECAO_IMEDIATA}
                          name='CORRECAO_IMEDIATA'
                          minRows={3}
                        />

                        <Textarea
                          required
                          label="Ação para correção da causa raiz"
                          placeholder='Ex: Em caso de recoleta, oferecer apoio e orientação ao paciente para garantir uma nova coleta bem-sucedida, agilizar o processamento do material coletado.'
                          defaultValue={item.CORRECAO_RAIZ}
                          name='CORRECAO_RAIZ'
                          minRows={3}
                        />
                      </SimpleGrid>
                      <SimpleGrid sx={{ marginTop: 10 }} cols={1} spacing="xl" verticalSpacing="sm">
                        <Textarea
                          label="Benchmark"
                          defaultValue={item.BENCHMARK}
                          name='BENCHMARK'
                          minRows={3}
                        />
                      </SimpleGrid>
                      <SimpleGrid sx={{ marginTop: 20 }} cols={3} spacing="xl" verticalSpacing="xl">
                        <DateInput
                          valueFormat="DD/MM/YYYY"
                          label="Prazo"
                          name='PRAZO'
                          size='md'
                          required
                          placeholder="Qual o prazo para esse plano?"
                        />
                        <Select
                          label="Status"
                          placeholder="Selecione o Status atual"
                          name='STATUS'
                          size='md'
                          required
                          data={['Em andamento', 'Concluído', 'Cancelado']}
                        />
                        <Select
                          label="Eficaz"
                          placeholder="Caso já tenha concluido, informe aqui se foi eficaz."
                          name='EFICAZ'
                          size='md'
                          required
                          data={['Em andamento', 'Sim', 'Não']}
                        />

                      </SimpleGrid>

                      <Button size='md' name='ID' value={item._id} sx={{ marginTop: 40, marginBottom: 30, width: '100%' }} type="submit" variant="filled">
                        Atualizar
                      </Button>
                    </form>

                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={5}>
                  <Button
                    onClick={() => handleCollapseToggle(index)}
                    size="sm"
                    fullWidth
                    variant={expanded.includes(index) ? 'outline' : 'light'}
                  >
                    Detalhes
                  </Button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

