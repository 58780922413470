import React, { useState } from 'react';
import { SegmentedControl, Box, Center, Container, Grid, Badge } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { KpiPainel } from './KpiPainel';
import { FMEAForm } from './FmeaForm';
import {CollapsibleTable} from './TablePainel';

export function JustifyData({data}) {
  const [activeTab, setActiveTab] = useState('indicador');
  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'indicador':
        return <Indicador data={data}/>;
      case 'timeline':
        return <Timeline />;
      case 'fmea':
        return <FMEAForm data={data}/>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{width:'100%'}}>
    <Badge size="xl" radius="sm">{data.area} | {data.indicator} - {data.mes}/{data.ano}</Badge>
    <Grid sx={{justifyContent:'center', margin:10}}>
      <SegmentedControl
        value={activeTab}
        onChange={handleTabChange}
        data={[
          { value: 'indicador', 
          label: (
            <Center>
              <IconEye size="1rem" />
              <Box ml={10}>Indicador</Box>
            </Center>)

        },
          { value: 'fmea', 
          label: (
            <Center>
              <IconEye size="1rem" />
              <Box ml={10}>Novo plano - FMEA</Box>
            </Center>)
        },
        ]}
      />
    </Grid>
      <Container size={'xl'} shadow="xs" padding="md" marginTop="sm" >
        {renderForm()}
      </Container>
    </Box>
  );
}

function Indicador({data}) {
  // Implemente o formulário para Justificativas aqui
  return (
    <>
    <KpiPainel valuesData={data}/>
    <CollapsibleTable valuesData={data}/>
    </>
  );
}

function Timeline() {
  // Implemente o formulário para Criar aqui
  return <div>Timeline</div>;
}

  
