import { RingProgress, Text, SimpleGrid, Paper, Center, Group, Card, Badge } from '@mantine/core';
import { IconBoxSeam, IconSquareToggleHorizontal, IconClipboardList } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { AppApi } from '../services/api/app-get/Api';
import { DataContext } from '../context/DataContext';



const icons = {
  list: IconClipboardList,
  pack: IconBoxSeam,
  parcial: IconSquareToggleHorizontal,
};



export function Kpis() {
  const [value, setValue] = useState([]);
  const { group } = useContext(DataContext)

  useEffect(()=>{

    AppApi(`/bsc/kpi/${group}/2023`,'GET','').then((result) =>{
      setValue(result)
    })

  },[group])

  const data = [{
    label: 'Planos Em andamento',
    stats: `${value ? value.AND : 0}`,
    progress: 100,
    color: 'blue',
    icon: 'list',
  },{
    label: 'Planos Concluidos',
    stats: `${value ? value.CON : 0}`,
    progress: 100,
    color: 'blue',
    icon: 'list',
  },]
  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    return (
      <Paper withBorder sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#F8F8F8',
      })} radius="md" p="xs" key={stat.label}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
            label={
              <Center>
                <Icon size="1.4rem" stroke={1.5} />
              </Center>
            }
          />

          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {stat.stats} /ano atual
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  const badges = [{
    label:'Plano Pendente',
    color: 'red',
  },
  {
    label:'Plano em andamento',
    color: 'yellow',
  },
  {
    label:'Plano concluído',
    color: 'blue',
  },
  {
    label:'Dentro da meta',
    color: 'green',
  },
  {
    label:'Sem dados',
    color: 'dark',
  }]

  const features = badges.map((badge) => (
    <Badge
      key={badge.label}
      color={badge.color}
      size='md'
      radius={'sm'}
    >
      {badge.label}
    </Badge>
  ));
  
  return (
    <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
      {stats}
      
      <Card.Section >
      <Text mt="md" c="dimmed">
        Legenda
      </Text>
      <Group spacing={7} mt={5}>
        {features}
      </Group>
    </Card.Section>

    </SimpleGrid>
  );
}







