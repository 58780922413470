// Importando as dependências necessárias
import React, { createContext, useEffect, useState } from "react"; 
import Cookies from 'js-cookie'; 
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext(); // Criando o contexto de autenticação

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Definindo o estado inicial do usuário como nulo
  const [loading, setLoading] = useState(true); // Definindo o estado de carregamento como verdadeiro

  useEffect(() => {
    
    async function fet(){
      const token = Cookies.get('_tk'); // Obtendo o token do cookie chamado "_tk"

      if (token) {
        const decodedToken = await jwtDecode(token); // Decodificando o token usando jwt-decode
        setUser(decodedToken); // Armazenando as informações do usuário decodificadas no estado
        setLoading(false)
      }

      if (!token) {
        const url_atual = window.location.href; // Obtendo a URL atual
        Cookies.set('_url', url_atual); // Armazenando a URL atual em um cookie chamado "_url"
        //window.location.href = `https://sgdi.fleury.com.br/login/`; // Redirecionando o usuário para a página de login
        return
      }
  

    }
    fet();
  }, []);

  const logout = () => {
    Cookies.remove('_tk'); // Removendo o cookie do token
    setUser(null); // Definindo o estado do usuário como nulo
    const url_atual = window.location.href; // Obtendo a URL atual
    Cookies.set('_url', url_atual); // Armazenando a URL atual em um cookie chamado "_url"
    window.location.href = `https://sgdi.fleury.com.br/login/`; // Redirecionando o usuário para a página de login
};

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
