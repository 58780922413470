import { Outlet } from "react-router-dom"


export function AppStart(){

    return(
        <> 
          <Outlet/>
        </>
    )

}



