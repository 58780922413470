import React, { createContext, useContext, useEffect, useState } from "react";
import { AppApi } from "../services/api/app-get/Api";
import { AuthContext } from "./AuthContext";
import { Environment } from "../environment";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [group, setGroup] = useState(null);
  const [kpiData, setKpiData] = useState([]);
  const [data, setData] = useState(null);
  const [unit, setUnit] = useState(null);
  const [load, setLoading] = useState(true); // Definindo o estado de carregamento como verdadeiro

  useEffect(() => {
    if (user)
      fetchData();
    else
      return
    async function fetchData() {
      const name = await user.mailNickname;
      const requestGroup = await AppApi(
        `/app/group/${Environment.APP_ID}?login=${name}`,
        "GET",
        ""
      )
      setGroup(requestGroup ? requestGroup.bsc : null)

      const requestData = await AppApi(
        `/bsc/painelTeste/${group}`, 
        "GET",
        ""
      );
      setData(requestData);
      setLoading(false)
            
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);



  async function fetchData(ano) {
    if (!group)
      return

    const requestData = await AppApi(
      `/bsc/painelTeste/${group}/${ano}`,
      "GET",
      ""
    );
    setData(requestData);
    setLoading(false)
  }

  async function fetchUnit() {

    const requestData = await AppApi(
      `/bsc/unidades/`,
      "GET",
      ""
    );
    setUnit(requestData);
  }

  async function fetchKpi() {

    const requestData = await AppApi(
      `/bsc/kpi/${group}/2023`,
      "GET",
      ""
    );
    setKpiData(requestData ? requestData : [])
  }

  const contextValue = {
    load,
    data,
    unit,
    group,
    kpiData,
    fetchData,
    fetchUnit,
    fetchKpi,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};
