import { createStyles, Title, Container, Group, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(80),
    paddingBottom: rem(120),
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
  },
  inner: {
    display: 'flex',
    width:'100%',
    justifyContent: 'center',
    flexDirection:'column',
  },
  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(220),
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][3] : theme.colors.dark[4],
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(38),
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[4],

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(32),
    },
  },

}));

export function NotFound() {
  const { classes } = useStyles();

  return (
    <div className={classes.inner}>
      <Container>
        <div className={classes.label}>{`Oops!`}</div>
        <Title className={classes.title}>Essa pagina não existe...</Title>
        <Group position="center">
        </Group>
      </Container>
    </div>
  );
}