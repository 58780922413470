// Importando as dependências necessárias
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'; 
import { useContext } from 'react';  
import { AuthContext, AuthProvider } from '../context/AuthContext';
import { AppStart, Home, NotFound, FirstAccess, Bench } from '../pages';
import { Environment } from '../environment';
import { AppShell, createStyles } from '@mantine/core';
import { NavbarMinimal } from '../components/Sidebar';
import { FooterLinks } from '../components/Footer';
import { Header } from '../components/Header';
import { DataContext, DataProvider } from '../context/DataContext';
import { ThemeProvider } from '../context/ThemeContext';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: `calc(${theme.spacing.xl} * 1)`,
    paddingLeft: `calc(${theme.spacing.xl} * 1)`,
  },
}));

// Componente de rotas do aplicativo
function AppRoutes() {
  // Componente Privado
  const Private = ({ children }) => {
    const { loading } = useContext(AuthContext); // Obtendo o estado de autenticação do contexto
        
    // Verificando se o carregamento está em andamento

    if (loading) {
      return <div className='loading'>carregando</div>; // Retorna uma mensagem de carregamento enquanto o estado está sendo verificado
    }

    // Verificando se o usuário está autenticado
    // Teste 123 add line

    return children;
  };

  const Restrict = ({ children }) => {
    const { group, load } = useContext(DataContext); // Obtendo o estado de dados do contexto
    if (load) {
      return <div className='loading'>carregando</div>; // Retorna uma mensagem de carregamento enquanto o estado está sendo verificado
    }

    if (!group) {
      return(
        <FirstAccess/>
      )
    }

    return children;
  };

  const { classes } = useStyles();

  return (
    <Router>
      <AuthProvider> 
 
        <DataProvider>
        <ThemeProvider>

        <AppShell
            navbar={<NavbarMinimal/>}
            footer={<FooterLinks/>}
            header={<Header/>}
           >
        <div className={classes.inner}>
        <Routes>            
           <Route path={`${Environment.APP_FOLDER}/`} element={<Private><><AppStart/></></Private>}> 
               <Route path="" element={<Restrict><Home/></Restrict>} />
               <Route path="Home" element={<Restrict><Home/></Restrict>} />
               <Route path="Bench" element={<Restrict><Bench/></Restrict>} />
               <Route path="Areas" element={<FirstAccess/>} />
               <Route path="*" element={<NotFound/>} /> 
           </Route>
        </Routes>
        </div>
          </AppShell>
          </ThemeProvider>
          </DataProvider>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes; // Exportando o componente AppRoutes
