// Importando as dependências necessárias
import { Environment } from '../../../environment';
import { Api } from '../axios-config/index.js';
import Cookies from 'js-cookie'; 

// Função assíncrona que realiza a chamada da API
export async function AppApi(url, method, value) { 
  const token = Cookies.get('_tk'); // Obtendo o token do cookie chamado "_tk"

  // Configurando os cabeçalhos da requisição
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': Environment.API_KEY,  // Definindo o valor do cabeçalho "x-api-key"
    Authorization: `Bearer ${token}` // Definindo o valor do cabeçalho "Authorization" com o token obtido
  }

  const config = {
    method,
    headers,
    data: value
  };


  try {
    const { data } = await Api(url, config); // Realizando a chamada da API usando a configuração do axios

    if (data) {
      return data; // Retornando os dados recebidos da API
    }
  } catch (error) {
    console.log("Error " + error); // Lidando com erros, exibindo uma mensagem de erro no console
  }
};



