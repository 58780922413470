import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Group, Avatar, Text, Box, Textarea, SimpleGrid, TextInput } from '@mantine/core';
import { AppApi } from '../services/api/app-get/Api';
import { notifications } from '@mantine/notifications';
import { DataContext } from '../context/DataContext';

export function CollapsibleTableBench({valuesData}){
    const [expanded, setExpanded] = useState([]);
    const [data, setData] = useState([]);
    const { fetchData } = useContext(DataContext)

    const handleCollapseToggle = (index) => {
      if (expanded.includes(index)) {
        setExpanded(expanded.filter((item) => item !== index));
      } else {
        setExpanded([...expanded, index]);
      }
    };

    useEffect(()=>{
      handlePlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handlePlans = async () =>{
      //const indi  = (valuesData.indicator == 'CUSTO TECNICO / RECEITA' ? 'RECEITA' : valuesData.indicator );
      const ajustData = {area: valuesData.area, indi: valuesData.indicator, ano: valuesData.ano, mes: valuesData.mes }
      const plans = await AppApi(`/bsc/plan/`, 'POST', JSON.stringify(ajustData));
      setData(plans);

    }
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Aqui você pode processar os dados do formulário, enviar para uma API, etc.
      const valuePlan = {
        ID:e.target.ID.value,
        ANO:valuesData.ano,
        MES:valuesData.mes,
        SETOR:valuesData.area,
        INDICADOR: valuesData.indicator,
        AREA_BENCHMARK: e.target.AREA_BENCHMARK.value,
        BENCHMARK: e.target.BENCHMARK.value,
      }
      const plano = JSON.stringify(valuePlan)
      AppApi(`/bsc/plano/update/`, 'POST', plano).then(()=>{
        notifications.show({
          title: 'Sucesso!',
          color: 'green',
          message: 'Plano atualizado com sucesso!',
        })
        fetchData()
      }).catch(()=>{
        notifications.show({
          title: 'Erro!',
          color: 'red',
          message: 'Ocorreu algum problema, tente novamente!',
        })
      })

    };
  
    return (
    <Box>
    <Text sx={{fontSize:32, marginTop:80}}>Plano de Ação</Text>
      <Table sx={{marginTop:20}}>
        <thead>
          <tr>
            <th>Area de Bench</th>
            <th>Benchmark</th>
            <th>Responsável pelo plano</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{item.AREA_BENCHMARK}</td>
                <td>{item.BENCHMARK}</td>
                <td>
                <Group spacing="sm">
                    <Avatar size={40} src={`https://sgdi.fleury.com.br/api/uploads/profile/${item.RESP_LOGIN.toLowerCase()}/profile.jpg`} radius={40} />
                    <div>
                    <Text fz="sm" fw={500}>
                    {item.RESP_NOME}
                    </Text>
                    <Text fz="xs" c="dimmed">
                    {item.RESP_EMAIL}
                    </Text>
                    </div>
                </Group>
                </td>
              </tr>
              {expanded.includes(index) && (
                <tr>
                  <td colSpan={5} >
                  <form onSubmit={handleSubmit}>

                  <SimpleGrid sx={{marginTop:10}} cols={1} spacing="xl" verticalSpacing="xl">

                    <TextInput
                        required
                        label="Área da Melhoria"
                        name='AREA_BENCHMARK'
                        size='md'
                        value={item.AREA_BENCHMARK}
                    />                    

                    <Textarea
                      required
                      label="Benchmark"
                      defaultValue={item.BENCHMARK}
                      name='BENCHMARK'
                      minRows={4}
                    />


                  </SimpleGrid>


                  <Button size='md' name='ID' value={item._id} sx={{marginTop:40, marginBottom:30, width:'100%'}} type="submit" variant="filled">
                    Atualizar
                  </Button>
                  </form>

                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={5}>
                  <Button
                    onClick={() => handleCollapseToggle(index)}
                    size="sm"
                    fullWidth
                    variant={expanded.includes(index) ? 'outline' : 'light'}
                  >
                    Detalhes
                  </Button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      </Box>
    );
  };

