import { useState } from 'react';
import {
  Badge,
  Box,
  Card,
  Drawer,
  Grid,
  Group,
  SegmentedControl,
  SimpleGrid,
  Skeleton,
  Text,
} from '@mantine/core';
import { useContext, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import { useDisclosure } from '@mantine/hooks';
import { JustifyData } from './JustifyData';

const inverterSinal = ['CONSULTORIAS MEDICAS', 'PM - EXAMES PREFERENCIAIS', 'PM - PEER REVIEW FLEURY', 'PM - PEER REVIEW Labs amais', 'PM - PEER REVIEW Felippe Mattoso', 'PM - PEER REVIEW AMAIS - SP', 'SEGUNDA LEITURA Felippe Mattoso', 'SEGUNDA LEITURA CPMA', 'SEGUNDA LEITURA FLEURY', 'SEGUNDA LEITURA Novamed', 'SEGUNDA LEITURA amais - SP', 'SEGUNDA LEITURA Labs amais', 'SEGUNDA LEITURA Serdil', 'SEGUNDA LEITURA amais - BA', 'SEGUNDA LEITURA DIAGMAX', 'SEGUNDA LEITURA amais - PE', 'SEGUNDA LEITURA IR'];
const anoInit = new Date().getFullYear();

const valideArea = (area) => {
  const validar = inverterSinal.includes(area);
  return validar;
}


const Status = (data, month, area_nome) => {
  const indicator = data; // Assumindo apenas um indicador
  const targetMonth = indicator.find((item) => item.MES === month);
  const validador = valideArea(area_nome)
  if (targetMonth && targetMonth.VALOR && targetMonth.META) {
    var valor = `${targetMonth.VALOR}`;
    var meta = (targetMonth.META ? `${targetMonth.META.META}` : '0');

    valor = valor.replace('%', '');
    meta = meta.replace('%', '');
    valor = valor.replace(/,/g, '.')
    meta = meta.replace(/,/g, '.')





    const sortedStatus = targetMonth.STATUS.sort((a, b) => b.STATUS.localeCompare(a.STATUS));

    // Obtém o primeiro valor após a ordenação
    const primeiroStatus = sortedStatus[0]?.STATUS || "N/A";
    //console.log('aqui ' + area_nome)


    if (Number(valor) <= Number(meta)) {
      return (validador ? 'red' : 'green');
    } else if (!targetMonth.STATUS && Number(valor) > Number(meta)) {
      return (validador ? 'green' : 'red');
    } else if (targetMonth.STATUS && primeiroStatus === 'Em andamento') {
      return 'yellow';
    } else if (targetMonth.STATUS && primeiroStatus === 'Concluído') {
      return 'blue';
    } else if (Number(valor) === '') {
      return 'dark';
    } else if (validador) {
      return 'green';
    }




  } else {
    return 'dark';
  }
};

function DetailsDrawer({ opened, onClose, selectedMonth }) {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position='right'
      size={'60%'}
      overlayProps={{ opacity: 0.5, blur: 4 }}
      closeOnClickOutside={false}
    >
      <JustifyData data={selectedMonth} />
    </Drawer>
  );
}

const Month = ({ data, indicator, area, ano, handleBadgeClick }) => {
  const months = [];

  for (let mes = 1; mes < 13; mes++) {
    const details = { indicator: indicator, area: area, ano: ano, mes: mes };
    months.push(
      <Badge
        color={Status(data, mes, area)}
        onClick={() => handleBadgeClick(details)} // Passa o valor de 'mes' para a função handleBadgeClick
        sx={{ margin: 1 }}
        size={6}
        radius="sm"
        key={mes}
      >
        {mes}
      </Badge>
    );
  }

  return <>{months}</>;
};

export const Painel = () => {
  const [opened, { toggle }] = useDisclosure(); // Use o estado 'opened' retornado pelo hook 'useDisclosure'
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [bscData, setBscData] = useState([])
  const [year, setYear] = useState(anoInit)
  const [loading, setLoading] = useState(true);
  const { data, fetchData } = useContext(DataContext);

  const handleBadgeClick = (data) => {
    setSelectedMonth(data);
    toggle(); // Abre o Drawer
  };

  useEffect(() => {
    if (Array.isArray(data) && data !== '') { // Verificar se data é um array
      setBscData(data);
    } else {
      fetchData(year).then((sucess) => {
        setLoading(false)
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleYear = (a) => {
    setLoading(true)
    fetchData(a).then((sucess) => {
      setLoading(false)
    })
    setYear(a)
  }

  const Ano = () => {

    return (
      <SegmentedControl
        sx={{ marginTop: 40 }}
        onChange={(e) => handleYear(e)}
        value={year}
        data={[
          { value: '2024', label: '2024', disabled: false },
          { value: '2023', label: '2023', disabled: false },
          { value: '2022', label: '2022', disabled: true },
          { value: '2021', label: '2021', disabled: true },
        ]}
      />
    )
  }

  return (
    <Box>

      <Ano />

      <Skeleton radius="md" sx={{ marginTop: 60 }} visible={loading}>
      <SimpleGrid cols={2} spacing="sm" verticalSpacing="sm">
        {bscData.map((value) => (
          <Card shadow="sm" padding="lg" radius="md" withBorder key={value.UNI_DE}>
            <Card.Section></Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>{value.UNI_DE}</Text>
              <Badge color="pink" radius="sm" variant="light">
                {year}
              </Badge>
            </Group>

            {

              value.INDICADORES.map((indi) => {
                //if(indi.IND_TIPO === 1){
                return (
                  <Grid key={indi.IND_NOME}>
                    <Grid.Col xs={6}>
                      <Badge
                        color="dark"
                        variant="light"
                        radius="sm"
                      >
                        <Text sx={{ fontSize: 10 }}>{indi.IND_NOME}</Text>
                      </Badge>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      <Box sx={{ marginLeft: 28 }}>
                        <Month
                          data={indi.MESES}
                          indicator={indi.IND_NOME}
                          area={value.UNI_PARA}
                          ano={year}
                          handleBadgeClick={handleBadgeClick} // Passe a função handleBadgeClick para a função Month
                        />
                      </Box>
                    </Grid.Col>
                  </Grid>
                )
                // }


              })

            }
          </Card>
        ))}
        <DetailsDrawer opened={opened} onClose={toggle} selectedMonth={selectedMonth} />
      </SimpleGrid>
      </Skeleton>
    </Box>
  );
};
