import {
    createStyles,
  } from '@mantine/core';
import { Kpis } from '../features/Kpis';
import { useContext, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import { Painel } from '../features/Painel';
  
  const useStyles = createStyles((_theme) => ({
    inner: {
      display: 'flex',
      width:'100%',
      justifyContent: 'center',
      flexDirection:'column',
    },


  }));



  export function Home() {
    const { classes } = useStyles();
    const { fetchData } = useContext(DataContext);
    useEffect(()=>{
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
      <div className={classes.inner}>
          <Kpis/>
          <Painel/>
      </div>
    );
  }