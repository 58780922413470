import { SegmentedControl, Group, Center, Box } from '@mantine/core';
import { IconSun, IconMoon } from '@tabler/icons-react';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export function ThemeToggle() {
  const {theme, toggleTheme} = useContext(ThemeContext);

  return (
    <Group position="center">
      <SegmentedControl
        value={theme}
        onChange={(e) =>toggleTheme()}
        data={[
          {
            value: 'light',
            label: (
              <Center>
                <IconSun size="1rem" color='orange' stroke={1.5} />
                <Box ml={10}>Light</Box>
              </Center>
            ),
          },
          {
            value: 'dark',
            label: (
              <Center>
                <IconMoon size="1rem" stroke={1.5} />
                <Box ml={10}>Dark</Box>
              </Center>
            ),
          },
        ]}
      />
    </Group>
  );
}