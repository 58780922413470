import React, { useContext, useState } from 'react';
import { TextInput, Textarea, Button, Grid, SimpleGrid } from '@mantine/core';
import { AppApi } from '../services/api/app-get/Api';
import { notifications } from '@mantine/notifications';
import { DataContext } from '../context/DataContext';




export const BencForm = ({data}) => {
    const [indicador, setIndicador] = useState(data.indicator);
    const [areaBench, setAreaBench] = useState('');
    const [benchmark, setBenchmark] = useState('');
    const { fetchData } = useContext(DataContext);


    const handleSubmit = (e) => {
      e.preventDefault();
      // Aqui você pode processar os dados do formulário, enviar para uma API, etc.
      const valuePlan = {
        ANO:data.ano,
        MES:data.mes,
        SETOR:data.area,
        INDICADOR: data.indicator,
        AREA_BENCHMARK: areaBench,
        BENCHMARK: benchmark,
      }
      const plano = JSON.stringify(valuePlan)
      AppApi(`/bsc/plano`, 'POST', plano).then(()=>{
        notifications.show({
          title: 'Sucesso!',
          color: 'green',
          message: 'Plano registrado com sucesso!',
        })
        fetchData()
      }).catch(()=>{
        notifications.show({
          title: 'Erro!',
          color: 'red',
          message: 'Ocorreu algum problema, tente novamente!',
        })
      })

    };
  
    return (
      <form onSubmit={handleSubmit}>
        <SimpleGrid sx={{marginTop:0}} cols={1} spacing="xl" verticalSpacing="xl">

        <TextInput
          required
          label="Indicador"
          size='md'
          value={indicador}
          disabled
          onChange={(e) => setIndicador(e.target.value)}
        />
        </SimpleGrid>
        <SimpleGrid sx={{marginTop:10}} cols={1} spacing="xl" verticalSpacing="xl">

        <Textarea
          required
          label="Benchmark"
          placeholder="Descreva o benchmark realizado"
          value={benchmark}
          minRows={3}
          onChange={(e) => setBenchmark(e.target.value)}
        />

        </SimpleGrid>
        <Grid sx={{marginTop:20}}>
        <Grid.Col span={8}>

        </Grid.Col>
        </Grid>

          <SimpleGrid sx={{marginTop:20}} cols={1} spacing="xl" verticalSpacing="xl">
          <TextInput
          required
          label="Area de benchmark"
          size='md'
          value={areaBench}
          onChange={(e) => setAreaBench(e.target.value)}
        />
          </SimpleGrid>

        <Button size='lg' sx={{marginTop:40, width:'100%'}} type="submit" variant="filled">
          Salvar
        </Button>
      </form>
    );
  };
