import { createStyles, Group, Paper, Text, SimpleGrid, RingProgress, Center } from '@mantine/core';
import { IconSquareRoundedMinus } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { AppApi } from '../services/api/app-get/Api';
import { IconAlertSquareRounded } from '@tabler/icons-react';
import { IconSquareRoundedCheck } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));



export function KpiPainel({ valuesData }) {
  const { classes } = useStyles();
  const [values, setValues] = useState([])

  useEffect(() => {
    handleIndi()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const icons = {
    up: IconSquareRoundedCheck,
    down: IconAlertSquareRounded,
    nop: IconSquareRoundedMinus
  };

  const handleIndi = async () => {
    //const indi  = (valuesData.indicator == 'CUSTO TECNICO / RECEITA' ? 'RECEITA' : valuesData.indicator );

    const ajustData = { area: valuesData.area, indi: valuesData.indicator, ano: valuesData.ano, mes: valuesData.mes }
    const data = await AppApi(`/bsc/unique/`, 'POST', JSON.stringify(ajustData));
    //const data = await AppApi(`/bsc/unique/${valuesData.area}/${indi}/${valuesData.ano}/${valuesData.mes}`)


    setValues(data ? [data] : [])

  }
  const inverterSinal = ['CONSULTORIAS MEDICAS', 'PM - EXAMES PREFERENCIAIS', 'PM - PEER REVIEW FLEURY', 'PM - PEER REVIEW Labs amais', 'PM - PEER REVIEW Felippe Mattoso', 'PM - PEER REVIEW AMAIS - SP', 'SEGUNDA LEITURA Felippe Mattoso', 'SEGUNDA LEITURA CPMA', 'SEGUNDA LEITURA FLEURY', 'SEGUNDA LEITURA Novamed', 'SEGUNDA LEITURA amais - SP', 'SEGUNDA LEITURA Labs amais', 'SEGUNDA LEITURA Serdil', 'SEGUNDA LEITURA amais - BA', 'SEGUNDA LEITURA DIAGMAX', 'SEGUNDA LEITURA amais - PE', 'SEGUNDA LEITURA IR'];

  // eslint-disable-next-line no-unused-vars
  const valideArea = (area) => {
    const validar = inverterSinal.includes(area);
    return validar;
  }

  const stats = values.map((stat) => {
    var valor = 0;
    var meta = 0;

    valor = stat.real ? stat.real.VALOR.replace('%', '') : valor;
    meta = stat.meta ? stat.meta.VALOR.replace('%', '') : meta;
    valor = valor !== 0 ? valor.replace(/,/g, '').replace('.', '') : valor;
    meta = meta !== 0 ? meta.replace(/,/g, '').replace('.', '') : meta;
    


    const Icon = icons[(stat.real && stat.meta ? valor < meta ? 'up' : 'down' : 'nop')];
    const color = stat.real && stat.meta ? valor < meta ? 'green' : 'red' : 'gray';

    return (
      <Paper withBorder radius="md" p="xs" key={(stat.real ? stat.real._id : 0)}>
        <Group>
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: 100, color: color }]}
            label={
              <Center>
                <Icon size="1.4rem" stroke={1.5} />
              </Center>
            }
          />

          <div>
            <Text color="white" size={14} transform="uppercase" weight={700}>
              {valuesData.indicator}
            </Text>
            <Text sx={{ textAlign: 'center' }} weight={700} size="xl">
              {(stat.real ? stat.real.VALOR : 0)} / Real
            </Text>
          </div>

        </Group>
        <Text sx={{ textAlign: 'right' }} weight={700} size="sm">
          Meta {(stat.meta ? stat.meta.VALOR : 0)}
        </Text>
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {stats}
      </SimpleGrid>
    </div>
  );
}