import React, { useContext, useState } from 'react';
import { TextInput, Textarea, Button, Container, Grid, SimpleGrid, Slider, Text, Card, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { AppApi } from '../services/api/app-get/Api';
import { notifications } from '@mantine/notifications';
import { DataContext } from '../context/DataContext';




export const FMEAForm = ({ data }) => {
  const [indicador, setIndicador] = useState(data.indicator);
  const [modoFalha, setModoFalha] = useState('');
  const [risco, setRisco] = useState('');
  const [causaRaiz, setCausaRaiz] = useState('');
  const [medidasDetecao, setMedidasDetecao] = useState('');
  const [probabilidade, setProbabilidade] = useState(1);
  const [gravidade, setGravidade] = useState(1);
  const [prevencao, setPrevencao] = useState(1);
  const [acoesMitigacao, setAcoesMitigacao] = useState('');
  const [acoesCorretivas, setAcoesCorretivas] = useState('');
  const [benchmark, setBenchmark] = useState('');
  const [prazo, setPrazo] = useState(null);
  const [status, setStatus] = useState('');
  const [eficaz, setEficaz] = useState('');
  const { fetchData } = useContext(DataContext);


  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode processar os dados do formulário, enviar para uma API, etc.
    const valuePlan = {
      ANO: data.ano,
      MES: data.mes,
      SETOR: data.area,
      INDICADOR: data.indicator,
      MODO_FALHA: modoFalha,
      RISCO: risco,
      CAUSA_RAIZ: causaRaiz,
      MEDIDA_DETECCAO: medidasDetecao,
      PROBABILIDADE: probabilidade,
      GAVIDADE: gravidade,
      PREVENCAO: prevencao,
      RPN: Number(probabilidade * gravidade * prevencao),
      CORRECAO_IMEDIATA: acoesMitigacao,
      CORRECAO_RAIZ: acoesCorretivas,
      BENCHMARK: benchmark,
      PRAZO: prazo,
      STATUS: status,
      EFICAZ: eficaz,
    }
    const plano = JSON.stringify(valuePlan)
    AppApi(`/bsc/plano`, 'POST', plano).then(() => {
      notifications.show({
        title: 'Sucesso!',
        color: 'green',
        message: 'Plano registrado com sucesso!',
      })
      fetchData()
    }).catch(() => {
      notifications.show({
        title: 'Erro!',
        color: 'red',
        message: 'Ocorreu algum problema, tente novamente!',
      })
    })

  };

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid sx={{ marginTop: 0 }} cols={2} spacing="xl" verticalSpacing="xl">

        <TextInput
          required
          label="Indicador"
          size='md'
          value={indicador}
          disabled
          onChange={(e) => setIndicador(e.target.value)}
        />

        <TextInput
          required
          label="Modo de falha"
          size='md'
          placeholder="Ex: Falha em coletar material biológico suficiente na primeira tentativa"
          value={modoFalha}
          onChange={(e) => setModoFalha(e.target.value)}
        />
      </SimpleGrid>
      <SimpleGrid sx={{ marginTop: 10 }} cols={3} spacing="xl" verticalSpacing="xl">

        <Textarea
          required
          label="Risco"
          placeholder="Ex: Atraso no diagnóstico e no tratamento do paciente"
          value={risco}
          minRows={3}
          onChange={(e) => setRisco(e.target.value)}
        />

        <Textarea
          required
          label="Causa raiz"
          placeholder="Ex: Coleta inadequada, instruções de coleta insuficientes ou não seguidas corretamente"
          value={causaRaiz}
          minRows={3}
          onChange={(e) => setCausaRaiz(e.target.value)}
        />

        <Textarea
          required
          label="Medidas de detecção"
          placeholder="Ex: Análise laboratorial revela quantidade insuficiente de material biológico para análise completa"
          value={medidasDetecao}
          minRows={3}
          onChange={(e) => setMedidasDetecao(e.target.value)}
        />
      </SimpleGrid>
      <Grid sx={{ marginTop: 20 }}>
        <Grid.Col span={8}>
          <SimpleGrid cols={1} spacing="xl" verticalSpacing="sm">

            <>
              <Text sx={{ marginTop: 15 }}>Probabilidade</Text>
              <Slider
                min={1}
                max={3}
                onChange={(e) => setProbabilidade(e)}
                marks={[
                  { value: 1, label: 'Baixa' },
                  { value: 2, label: 'Moderada' },
                  { value: 3, label: 'Alta' },
                ]} />
            </>

            <>
              <Text sx={{ marginTop: 15 }}>Gravidade</Text>
              <Slider
                min={1}
                max={3}
                onChange={(e) => setGravidade(e)}
                marks={[
                  { value: 1, label: 'Baixa' },
                  { value: 2, label: 'Moderada' },
                  { value: 3, label: 'Alta' },
                ]} />
            </>

            <>
              <Text sx={{ marginTop: 15 }}>Prevencao</Text>
              <Slider
                min={1}
                max={3}
                onChange={(e) => setPrevencao(e)}
                marks={[
                  { value: 1, label: 'Baixa' },
                  { value: 2, label: 'Moderada' },
                  { value: 3, label: 'Alta' },
                ]} />
            </>

          </SimpleGrid>
        </Grid.Col>
        <Grid.Col span={4}>
          <Grid sx={{ justifyItems: 'center', justifyContent: 'center' }}>

            <Container sx={{ width: '100%', height: '100%', marginTop: 65 }}>
              <Card
                withBorder
                radius="md"
                padding="xl"
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
              >
                <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                  RPN
                </Text>
                <Text fz="lg" fw={500}>
                  {Number(probabilidade * gravidade * prevencao)}
                </Text>
                <Slider value={Number(probabilidade * gravidade * prevencao)} max={27} mt="md" size="lg" radius="xl" />
                <Text fz="xs" fw={700} c="dimmed">
                  {`O valor do risco (RPN) é um múltiplo de 3 variáveis (Probabilidade, Gravidade e Prevencao)`}
                </Text>
              </Card>
            </Container>
          </Grid>
        </Grid.Col>
      </Grid>
      <SimpleGrid sx={{ marginTop: 60 }} cols={2} spacing="xl" verticalSpacing="sm">

        <Textarea
          required
          label="Ação para correção imediata"
          placeholder="Ex: Melhorar as instruções de coleta, fornecer treinamento adicional para profissionais de saúde, implementar procedimentos de controle de qualidade para garantir coletas adequadas."
          value={acoesMitigacao}
          minRows={3}
          onChange={(e) => setAcoesMitigacao(e.target.value)}
        />

        <Textarea
          required
          label="Ação para correção da causa raiz"
          placeholder='Ex: Em caso de recoleta, oferecer apoio e orientação ao paciente para garantir uma nova coleta bem-sucedida, agilizar o processamento do material coletado.'
          value={acoesCorretivas}
          minRows={3}
          onChange={(e) => setAcoesCorretivas(e.target.value)}
        />

      </SimpleGrid>
      <SimpleGrid sx={{ marginTop: 20 }} cols={1} spacing="xl" verticalSpacing="xl">
          <Textarea
            label="Benchmark"
            placeholder=''
            value={benchmark}
            minRows={3}
            onChange={(e) => setBenchmark(e.target.value)}
          />
        </SimpleGrid>
      <SimpleGrid sx={{ marginTop: 20 }} cols={3} spacing="xl" verticalSpacing="xl">
        <DateInput
          value={prazo}
          valueFormat="DD/MM/YYYY"
          onChange={setPrazo}
          label="Prazo"
          size='md'
          required
          placeholder="Qual o prazo para esse plano?"
        />
        <Select
          label="Status"
          placeholder="Selecione o Status atual"
          value={status}
          onChange={setStatus}
          size='md'
          required
          data={['Em andamento', 'Concluído', 'Cancelado']}
        />
        <Select
          label="Eficaz"
          placeholder="Caso já tenha concluido, informe aqui se foi eficaz."
          value={eficaz}
          size='md'
          onChange={setEficaz}
          data={['Em andamento', 'Sim', 'Não']}
        />

      </SimpleGrid>

      <Button size='lg' sx={{ marginTop: 40, width: '100%' }} type="submit" variant="filled">
        Salvar
      </Button>
    </form>
  );
};
